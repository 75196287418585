import React from "react";

interface IProjects {
  name: string;
  description: string;
}

const Projects: React.FC = () => {
  const projects: IProjects[] = [
    { name: "Project 1", description: "Description" },
    { name: "Project 2", description: "Description" },
  ];

  return (
    <div id="projects" style={{ paddingTop: "14px" }}>
      <h2> {"Projects"}</h2>
      <div id={"projects-list"}>
        {projects.map((project) => {
          return <div>{project.name}</div>;
        })}
      </div>
    </div>
  );
};

export default Projects;
