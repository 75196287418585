import java from "./assets/img/skills/java.png";
import javascript from "./assets/img/skills/javascript.png";
import spring from "./assets/img/skills/spring.png";
import react from "./assets/img/skills/react.png";
import sql from "./assets/img/skills/postgresql.png";
import html from "./assets/img/skills/html.png";
import css from "./assets/img/skills/css.png";
import typescript from "./assets/img/skills/typescript.png";
import kotlin from "./assets/img/skills/kotlin.png";
import intuit from "./assets/img/companies/intuit_logo.png";
import northrop from "./assets/img/companies/ng_logo.png";
import rvcm from "./assets/img/companies/rvcm_logo.svg";
import sym from "./assets/img/companies/symvionics_logo.png";

export const backendSkills = [
  {
    img: java,
    title: "Java",
  },
  {
    img: kotlin,
    title: "Kotlin",
  },
  {
    img: spring,
    title: "Spring",
  },
  {
    img: sql,
    title: "PostgreSQL",
  },
];

export const frontendSkills = [
  {
    img: html,
    title: "HTML",
  },
  {
    img: css,
    title: "CSS",
  },
  {
    img: javascript,
    title: "Javascript",
  },
  {
    img: typescript,
    title: "Typescript",
  },
  {
    img: react,
    title: "React",
  },
];

export const companies = [
  {
    img: intuit,
    name: "Intuit, Inc",
    link: "https://www.intuit.com",
  },
  {
    img: northrop,
    name: "Northrop Grumman",
    link: "https://www.northropgrumman.com",
  },
  {
    img: rvcm,
    name: "Rvcm",
    link: "https://www.rvcm.com",
  },
  {
    img: sym,
    name: "Symvionics, Inc",
    link: "https://www.symvionics.com",
  },
];
