/**
 * SDElements specified way to open a link in a new tab to Restrict access to opener
 * @param link: url string that you would like top open in a new tab
 */
export const openLinkInNewTab = (link: string) => {
  const B = window.open();

  if (B) {
    B.opener = null;
    B.location.href = link;
  }
};
