import { useRef } from "react";

export const useScroll = () => {
  const ref = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    // @ts-ignore
    ref.current.scrollIntoView();
  };

  return [ref, executeScroll] as const;
};
