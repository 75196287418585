import React from "react";
import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import { backendSkills, frontendSkills } from "../../const";

const Skills: React.FC = () => {
  const frontendContainer = () => {
    const skills = () => {
      return (
        <ImageList sx={{ width: 200 }} cols={6} rowHeight={"auto"}>
          {frontendSkills.map((skill) => {
            return (
              <ImageListItem key={skill.title}>
                <Tooltip
                  title={skill.title}
                  children={
                    <img src={skill.img} alt={skill.title} loading={"lazy"} />
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      );
    };
    return (
      <div
        className={"flex flex-column pa3 w-50 mr1"}
        style={{ border: "1px dashed #EFEFEF", borderRadius: "25px" }}
      >
        <div className={"flex fw5 pb2"}>{"Front-end Development"}</div>
        <div>
          <div className={"f7 mt1"} style={{ fontSize: "8px" }}>
            Languages/Frameworks I use:
          </div>
          {skills()}
        </div>
        <div className={"fw3 gray mb2"} style={{ fontSize: "12px" }}>
          Develop graphical user interface of a web application and improve
          overall user experience to enhance website navigation
        </div>
      </div>
    );
  };

  const backendContainer = () => {
    const skills = () => {
      return (
        <ImageList sx={{ width: 200 }} cols={6} rowHeight={"auto"}>
          {backendSkills.map((skill) => {
            return (
              <ImageListItem key={skill.title}>
                <Tooltip
                  title={skill.title}
                  children={
                    <img src={skill.img} alt={skill.title} loading={"lazy"} />
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      );
    };

    return (
      <div
        className={"ml1 pa3 flex flex-column w-50"}
        style={{ border: "1px dashed #EFEFEF", borderRadius: "25px" }}
      >
        <div className={"flex fw5 pb2"}>{"Back-end Development"}</div>
        <div>
          <div className={"f7 mt1"} style={{ fontSize: "8px" }}>
            Languages/Frameworks I use:
          </div>
          {skills()}
        </div>
        <div className={"fw3 gray mb2"} style={{ fontSize: "12px" }}>
          Work on server-side software, focusing on creating and managing
          databases, creating API or Application Programming Interface, and
          improving application performance
        </div>
      </div>
    );
  };
  return (
    <div id={"skills"} className={"flex flex-column w-100 items-center"}>
      <div
        className={"flex mb4"}
        style={{ borderBottom: "2px solid #EFEFEF", width: "5%" }}
      />

      <div className={"flex flex-column fw6 f3 pb4 justify-center"}>
        Experience and Expertise
      </div>

      <div className={"flex w-50 pb5"}>
        {frontendContainer()}
        {backendContainer()}
      </div>
    </div>
  );
};

export default Skills;
