import React from "react";
import About from "../About/About";
import Skills from "../Skills/Skills";
import Experience from "../Experience/Experience";
import Contact from "../Contact/Contact";
import { useScroll } from "../../hooks/scrollHook";
import Navbar, { INavbarObject } from "../Navbar/Navbar";
import Projects from "../Projects/Projects";
import { openLinkInNewTab } from "../../utils/utils";

const Home: React.FC = () => {
  const [aboutRef, scrollToAbout] = useScroll();
  const [skillsRef, scrollToSkills] = useScroll();
  const [expRef, scrollToExperience] = useScroll();
  const [projRef, scrollToProjects] = useScroll();
  const [contactRef, scrollToContact] = useScroll();

  const navbarObjects: INavbarObject[] = [
    { title: "home", onClick: scrollToAbout },
    { title: "skills", onClick: scrollToSkills },
    // { title: "Experience", onClick: scrollToExperience },
    // { title: "Projects", onClick: scrollToProjects },
    { title: "contact", onClick: scrollToContact },
    {
      title: "resume",
      onClick: () => {
        openLinkInNewTab(
          "https://drive.google.com/file/d/1y5yg5adq0CvDQ8_vGFiZW4BcJ9AEnArh/view?usp=sharing"
        );
      },
    },
  ];

  return (
    <div id={"home-component"} className={"h-100"}>
      <div
        className={"ph4 pt4"}
        style={{
          backgroundColor: "black",
          color: "white",
        }}
      >
        <Navbar navbarObjects={navbarObjects} />
      </div>

      <div ref={aboutRef} className={"pv4"}>
        <About />
      </div>

      <div ref={skillsRef} className="flex flex-column">
        <Skills />
        {/* <Experience /> */}
      </div>

      {/*<div ref={projRef}>*/}
      {/*  <Projects />*/}
      {/*</div>*/}

      <div
        ref={contactRef}
        className={"pv4"}
        style={{ backgroundColor: "#F7FBFA" }}
      >
        <Contact />
      </div>

      {/* TODO: Add Footer */}
    </div>
  );
};

export default Home;
