import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import React from "react";
import { companies } from "../../const";

interface IExperience {
  name: string;
  description: string;
  url?: string;
}

const Experience: React.FC = () => {
  const experiences: IExperience[] = [
    { name: "Intuit, Inc", description: "" },
    { name: "Revacomm", description: "Description" },
    { name: "Northrop Grumman", description: "Description" },
    { name: "Symvionics, Inc", description: "Description" },
  ];

  const exp = () => {
    return (
      <ImageList>
        {companies.map((company) => {
          return (
            <ImageListItem key={company.name} sx={{ width: 500 }} cols={6}>
              <Tooltip
                title={company.name}
                children={
                  <img src={company.img} alt={company.name} loading={"lazy"} />
                }
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  };
  return (
    <div
      id="experiences"
      className={"flex flex-column w-100"}
      style={{ paddingTop: "14px" }}
    >
      <h2> {"Where I've worked"}</h2>

      <div id={"companies"} className="flex flex-column">
        <div className="flex">{exp()}</div>
      </div>
    </div>
  );
};

export default Experience;
