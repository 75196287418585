import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";

export interface INavbarObject {
  title: string;
  onClick: () => void;
}

interface INavbarProps {
  navbarObjects: INavbarObject[];
}

const Navbar: React.FC<INavbarProps> = ({ navbarObjects }) => {
  const [value, setValue] = useState("home");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    const nav: INavbarObject | undefined = navbarObjects.find(
      (e) => e.title === newValue
    );

    if (nav) {
      nav.onClick();
    }
  };
  return (
    <>
      <Tabs
        value={value}
        textColor={"inherit"}
        onChange={handleChange}
        variant="fullWidth"
        centered
      >
        {navbarObjects ? (
          navbarObjects.map((nav, key) => {
            const value = nav ? nav.title : "";
            return <Tab key={key} value={value} label={value} />;
          })
        ) : (
          <div />
        )}
      </Tabs>
    </>
  );
};

export default Navbar;
