import React from "react";
import { GitHub, Instagram, LinkedIn } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { openLinkInNewTab } from "../../utils/utils";

export const Social: React.FC = () => {
  return (
    <div className={"flex"}>
      <div
        className={"flex"}
        style={{
          alignItems: "center",
        }}
      >
        {"Let's connect: "}
      </div>
      <div>
        <IconButton
          color={"info"}
          onClick={() => {
            openLinkInNewTab("https://linkedin.com/in/ocampomico");
          }}
        >
          <LinkedIn />
        </IconButton>

        <IconButton
          color={"inherit"}
          onClick={() => {
            openLinkInNewTab("https://github.com/ocampomico");
          }}
        >
          <GitHub />
        </IconButton>

        <IconButton
          color={"secondary"}
          onClick={() => {
            openLinkInNewTab("https://instagram.com/ocampomico");
          }}
        >
          <Instagram />
        </IconButton>
      </div>
    </div>
  );
};
