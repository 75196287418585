import React from "react";
import { Avatar, Grid } from "@mui/material";

import headshot from "../../assets/img/headshot_small.jpg";
import { Social } from "./Social";

const About: React.FC = () => {
  return (
    <div id={"about"} className="flex flex-column w-100">
      <div
        className={"flex-auto pv3 w-60"}
        style={{ margin: "auto", textAlign: "center" }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={""}
        >
          <Grid item>
            <Avatar
              alt={"Mico Ocampo"}
              src={headshot}
              sx={{ width: 180, height: 180 }}
            />
          </Grid>
          <Grid item xs={6}>
            <div className={"ml2"} style={{ textAlign: "left" }}>
              <h2>{"Hi, I'm Mico! 😊"}</h2>
              <p>{"I'm a Software Engineer based in Los Angeles, CA. ☀️"}</p>
              <p style={{ color: "gray" }}>
                I have a background in Computer Science and love tackling
                complex problems. I have experience in Full-stack development,
                including continuous integration and deployment. When I'm not
                working, I love to be outdoors and enjoying nature!
              </p>
              <Social />
            </div>
          </Grid>
        </Grid>

        {/*</div>*/}
      </div>
    </div>
  );
};

export default About;
