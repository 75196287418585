import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import emailjs from "emailjs-com";

const Contact: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault(); // *Avoid page from refreshing
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID!,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID!,
        e.target,
        process.env.REACT_APP_EMAIL_USER_ID!
      )
      .then(() => {
        setEmailSending(true);
      })
      .finally(() => {
        setInterval(() => {
          setEmailSending(false);
        }, 3000);

        setEmailSent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("sending...", emailSending);
  return (
    <div id="contact" className={"flex flex-column"}>
      <div className={"w-40"} style={{ margin: "auto", textAlign: "center" }}>
        <div className={"flex flex-column"}>
          <h1>{"Work with me!"}</h1>
        </div>

        <form id={"contact-form"} onSubmit={sendEmail}>
          <div className={"flex flex-column ph3"}>
            <TextField
              required
              id="text-user-name"
              name={"user_name"}
              label={"Name"}
              variant={"standard"}
              margin={"normal"}
              onChange={() => setEmailSent(false)}
            />

            <TextField
              required
              id={"text-contact-email"}
              name={"user_email"}
              label={"Email Address"}
              type={"email"}
              variant={"standard"}
              margin={"normal"}
              onChange={() => setEmailSent(false)}
            />

            <TextField
              required
              multiline
              id="text-message"
              name={"message"}
              label={"Message"}
              variant={"standard"}
              margin={"normal"}
              onChange={() => setEmailSent(false)}
            />

            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems={"center"}
              padding={"1rem"}
            >
              {!emailSending ? (
                <Button
                  disabled={emailSent}
                  variant="contained"
                  endIcon={emailSent ? <CheckIcon /> : <SendIcon />}
                  type={"submit"}
                >
                  {emailSent ? "Sent!" : "Send"}
                </Button>
              ) : (
                <CircularProgress />
              )}
            </Stack>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
