import Contact from "./components/Contact/Contact";
import Experience from "./components/Experience/Experience";
import Skills from "./components/Skills/Skills";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={"/home"} exact={true} component={Home} />
        <Route path={"/about"} exact={true} component={About} />
        <Route path={"/skills"} exact={true} component={Skills} />
        <Route path={"/experience"} exact={true} component={Experience} />
        <Route path={"/projects"} exact={true} component={Projects} />
        <Route path={"/contact"} exact={true} component={Contact} />
      </Switch>
      <Home />
    </Router>
  );
};

export default App;
